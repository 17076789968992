<template>
  <div class="text-center" >
    <v-dialog v-model="dialog" height="50vh" width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="primary"
          v-bind="attrs"
          v-on="on"
          text
        >
          {{folder.name}}
        </v-btn>
      </template>

      <v-card >
        <v-card-title class="headline grey lighten-2" >
          Détail
        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-simple-table height="300px">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Information</th>
                        <th class="text-left">Valeur</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="folder.mode.toLowerCase() == 'restore'"><td>Nom base de données</td><td>{{ folder.databaseName }}</td></tr>
                    <tr v-if="folder.mode.toLowerCase() == 'restore'"><td>Fichier backup</td><td>{{ folder.backupFile }}</td></tr>
                    <tr v-if="folder.mode.toLowerCase() == 'restore'"><td>Dernière restauration</td><td>{{ folder.lastDatabaseRestoreDateTime.substring(0,19).replace('T',' ') }}</td></tr>
                    <tr><td>Synchro ok</td><td>{{ folder.lastSynchroOk }}</td></tr>
                    <tr><td>Dernière synchro.</td><td>{{ folder.lastSynchroDatetime.substring(0,19).replace('T',' ') }}</td></tr>
                    <tr><td>Dernière erreur</td><td>{{ folder.lastSynchroError }}</td></tr>
                    <tr><td>Source</td><td>{{ folder.source }}</td></tr>
                    <tr><td>Destination</td><td>{{ folder.destination }}</td></tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    name: 'FolderDetail',
    props: {
        folder: Object
    },

    data () {
      return {
        dialog: false
      }
    },

    watch: {
      dialog(visible) {
        if (visible) {
          //console.log("Dialog was opened!")
        } else {
          //console.log("Dialog was closed!")
        }
      }
    },

      methods: {

    },

    mounted() {
    }
  }
</script>