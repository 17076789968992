<template>
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark color="error" v-bind="attrs" v-on="on">Activer la Flybox</v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" v-if="state != undefined">
          Activation de la {{state.flybox}} pour l'application {{nomAppli}}
        </v-card-title>

        <v-card-text class="red--text">
            <p>Attention, l'activation de la Flybox ne doit être lancée que dans le cadre de la procédure de redondance après une panne matérielle de la Flybox active.</p>

            <p>Par sécurité, veuillez saisir le code <b>{{codeSecurite}}</b> pour pouvoir lancer l'activation de la {{state.flybox}} pour l'application {{nomAppli}}</p>
            <v-text-field label="code de sécurité" @input="saisieCodeSecuriteChange" v-model="saisieCodeSecurite"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card v-if="erreur != ''" class="red white--text text-center ma-4 pa-4" flat>{{erreur}}</v-card>

        <v-card-actions>
            <!--<v-switch label="Forcer l'activation sans le bMaster" v-model="forcerActivation"></v-switch>-->
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="activer()" :loading="loading" v-if="autoriserBoutonActiver">activer</v-btn>
          <v-btn color="primary" @click="dialog = false" >annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import axios from 'axios'

export default {
    name: 'Activation',
    props: {
        state: undefined,
        ip: String,
        port: Number,
        nomAppli: String
    },

    data () {
      return {
        dialog: false,
        loading:false,
        forcerActivation: false,
        erreur: "",
        codeSecurite: "",
        saisieCodeSecurite: "",
        autoriserBoutonActiver: false
      }
    },

    watch: {
      dialog(visible) {
        if (visible) {
            this.erreur = "";
            this.codeSecurite = Math.floor(1000 + Math.random() * 9000).toString();
            console.log(this.codeSecurite);
            this.autoriserBoutonActiver = false;
          //console.log("Dialog was opened!")
        } else {
          //console.log("Dialog was closed!")
        }
      }
    },

      methods: {
        activer() {
            this.loading = true;
          var url = "http://{ip}:{port}/Flybox/{nomAppli}/app/controllers/main/modules/redondance/Activation";
          if (this.forcerActivation) {
              url = url + "?forcerActivation=1";
          }
          url = url.replace("{ip}", this.ip);
          url = url.replace("{port}", this.port.toString());
          url = url.replace("{nomAppli}", this.nomAppli);
          this.erreur = "";
          axios.get(url)
            .then(() => {
                //console.log(response.data);
                this.loading = false;
            })
            .catch(error => {
                //console.log(error.request.statusText);
                this.loading = false;
                this.erreur = error.request.statusText;
            });
      },

      saisieCodeSecuriteChange() {
          this.autoriserBoutonActiver = this.saisieCodeSecurite == this.codeSecurite;
          console.log(this.autoriserBoutonActiver, this.saisieCodeSecurite, this.codeSecurite);
      }

    },

    mounted() {
        //this.refresh();
    }
  }
</script>