<template>
  <v-card class="mx-auto pa-2">
      <flybox-bloquee-popup ref="flyboxBloqueePopup" ></flybox-bloquee-popup>

    <v-card-title>
        <h2 class="red" v-if="!commEtatOk">Erreur de communication</h2>
        <v-row>
            <v-col cols="8">Redondance : {{state.flybox}} {{nomAppli}} {{ip}}:{{port}}</v-col>
            <v-col cols="4"><v-btn @click="refresh" :loading="loading">recharger</v-btn></v-col>
        </v-row>
    </v-card-title>
    
    <v-layout row wrap v-if="state != undefined">
        <v-flex xs6 >
            <propriete nom="Démarrage" :valeur="((state != undefined) && (state.startTime != undefined)) ? state.startTime.substring(0,19).replace('T',' ') : ''" />
            <propriete nom="Ip flybox 1" :valeur="state.ipFlybox1" />
            <propriete nom="Ip flybox 2" :valeur="state.ipFlybox2" />
            <propriete-bool nom="Mode" :valeur="state.actif" texteOn="ACTIF" texteOff="INACTIF" />
            <propriete-bool nom="Mode dev." :valeur="state.modeDev" />
            <propriete-bool nom="Cnx Twincat" :valeur="state.connexionTwincat" v-if="!state.actif" />
        </v-flex>        
        <v-flex xs6 v-if="!state.actif"> 
            <propriete-bool nom="Flybox bloquée" :valeur="state.flyboxBloquee" texteOn="OUI" texteOff="NON" couleurOn="#FF0000" />
            <propriete-bool nom="bit bMaster" :valeur="state.bMaster"  />
            <propriete-bool nom="tag Activation" :valeur="state.tagActivation == 1 " />
            <propriete-bool nom="Forcer activation" :valeur="state.forcerActivation "/>
            <propriete-bool nom="Fin activation" :valeur="state.finActivation "  />
            <propriete nom="Accès base archive" :valeur="state.accesBaseArchive" />
        </v-flex>        
    </v-layout>

    <v-card-actions v-if="!state.actif">
        <v-btn class="primary" @click="testBaseArchive" :loading="loadingTestBaseArchive">Test base archive</v-btn>
        <comparaison-fichiers :state="state" :nomAppli="nomAppli" :ip="ip" :port="port" class="mx-3"></comparaison-fichiers>
        <traces :state="state" :nomAppli="nomAppli" :ip="ip" :port="port"></traces>
        <v-spacer></v-spacer>
        <activation :state="state" :nomAppli="nomAppli" :ip="ip" :port="port"></activation>
    </v-card-actions>

    <v-alert type="success" :value="alertTestBaseArchiveOk">
        Test d'accès à la base de données 'archive' réussi avec succès
    </v-alert>
    <v-alert type="error" :value="alertTestBaseArchiveErreur">
        Erreur d'accès à la base de données 'archive' : {{alertTestBaseArchiveMessage}}
    </v-alert>

    <h4 class="mt-4"  v-if="!state.actif">Dossiers synchronisés</h4>

    
    <v-data-table :items="state.folders" :headers="headers"  v-if="!state.actif">
        <template v-slot:item.lastSynchroOk="{ item }">
            <v-btn :color="item.lastSynchroOk ? 'success' : 'error'" text>{{ item.lastSynchroOk ? 'OK' : 'ERREUR' }}</v-btn> 
        </template>
        <template v-slot:item.name="{ item }">
            <folder-detail :folder="item"></folder-detail>
        </template>
        <template v-slot:item.lastSynchroDatetime="{ item }">
            <div color="primary" text>{{item.lastSynchroDatetime.substring(0,19).replace('T',' ')}}</div>
        </template>
    </v-data-table>

  </v-card>
</template>

<script>
import axios from 'axios'
import Propriete from './Propriete.vue';
import ProprieteBool from './ProprieteBool.vue';
import ComparaisonFichiers from './ComparaisonFichiers.vue';
import Activation from './Activation.vue';
import Traces from './Traces.vue';
import FolderDetail from './FolderDetail.vue';
import FlyboxBloqueePopup from './FlyboxBloqueePopup.vue'

export default {
  name: 'FlyboxRedondance',
  props: {
    ip: String,
    port: Number,
    nomAppli:String
  },

  data: () => ({
    state: {},
    headers: [
        {text: 'Nom', value:'name'},
        {text: 'Mode', value:'mode'},
        {text: 'Synchro', value:'lastSynchroDatetime', align: 'center'},
        {text: 'Résultat', value:'lastSynchroOk', align: 'center'}
    ],
    commEtatOk: true,
    loading: false,
    loadingTestBaseArchive: false,
    alertTestBaseArchiveOk: false,
    alertTestBaseArchiveErreur: false,
    alertTestBaseArchiveMessage: "?"
  }),

methods: {

      refresh () {
          var url = "http://{ip}:{port}/Flybox/{nomAppli}/app/controllers/main/modules/redondance/Etat";
          url = url.replace("{ip}", this.ip);
          url = url.replace("{port}", this.port.toString());
          url = url.replace("{nomAppli}", this.nomAppli);
          this.loading = true;
          axios.get(url)
            .then(response => {
                //console.log(response);
                this.state = response.data;
                if (this.state.flyboxBloquee) {
                    this.$refs.flyboxBloqueePopup.ouvrirDialog = true;
                }
                this.commEtatOk = true;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.commEtatOk = false;
                this.loading = false;
            });
      },
      
      testBaseArchive () {
          var url = "http://{ip}:{port}/Flybox/{nomAppli}/app/controllers/main/modules/redondance/TestBaseArchive";
          url = url.replace("{ip}", this.ip);
          url = url.replace("{port}", this.port.toString());
          url = url.replace("{nomAppli}", this.nomAppli);
          this.loadingTestBaseArchive = true;
          axios.get(url)
            .then((response) => {
                console.log(response.data);
                if (response.data.accesBaseArchive) {
                    this.alertTestBaseArchiveMessage = "";
                    this.alertTestBaseArchiveOk = true;
                    setTimeout( () => {this.alertTestBaseArchiveOk = false}, 5000);
                }
                else {
                    this.alertTestBaseArchiveMessage = "erreur";
                    this.alertTestBaseArchiveErreur = true;
                    setTimeout( () => {this.alertTestBaseArchiveErreur = false}, 5000);
                }

                this.loadingTestBaseArchive = false;

            })
            .catch((error) => {
                //console.log(error);
                this.loadingTestBaseArchive = false;
                this.alertTestBaseArchiveMessage =  error.request.statusText;
                this.alertTestBaseArchiveErreur = true;
                setTimeout( () => {this.alertTestBaseArchiveErreur = false}, 5000);

            });
      },
  },

  mounted() {
      this.refresh();
      setInterval(() => { this.refresh(); }, 5000);
  },

  components: { Propriete, ProprieteBool,  ComparaisonFichiers, Activation, Traces, FolderDetail, FlyboxBloqueePopup },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
