<template>
<v-container grid-list-md>
    <v-layout row wrap>
        <v-flex xs6 class="blue--text">
             {{nom}}
        </v-flex>        
        <v-flex xs6 style="text-align: left" >
          <div class="'pa-1" :style="'width: 80px; text-align: center; background-color: ' + (valeur ? couleurOn : couleurOff) + ';'">
             {{valeur ? texteOn : texteOff}}
          </div>
        </v-flex>        
    </v-layout>
    
</v-container>
</template>

<script>

export default {
  name: 'ProprieteBool',
  props: {
    nom: String,
    valeur: Boolean,
    texteOn: {
      type: String,
      default: "ON"
    },
    texteOff: {
      type: String,
      default: "OFF"
    },
    couleurOn: {
      type: String,
      default: "#00FF00"
    },
    couleurOff: {
      type: String,
      default: "#808080"
    }
  },

  data: () => ({
  }),

  methods: {
  },

  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vert {
  background-color: #00FF00;
}
</style>
