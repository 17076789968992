<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="6">
        <flybox-redondance :ip="domain" :port="8080" nomAppli="ageps"></flybox-redondance>
      </v-col>

      <v-col cols="6">
        <flybox-redondance :ip="domain" :port="8085" nomAppli="manager"></flybox-redondance>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FlyboxRedondance from './FlyboxRedondance.vue'

export default {
  components: { FlyboxRedondance },
    name: 'HelloWorld',

    data: () => ({
      domain: ""
    }),

  created() {
    this.domain = window.location.hostname;
    if ((this.domain === "localhost") || (this.domain === "127.0.0.1")) {
      this.domain = "192.168.10.180";
    }
    
  }
}
</script>
