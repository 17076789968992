<template>
  <div class="text-center" >
    <v-dialog v-model="dialog" height="50vh">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Traces
        </v-btn>
      </template>

      <v-card >
        <v-card-title class="headline grey lighten-2" v-if="state != undefined">
          {{state.flybox}} {{nomAppli}} traces de redondance
          <v-btn color="success" class="ml-4 mr-4" @click="aujourdhui()" :loading="loading">aujourd'hui</v-btn>
          <v-btn color="success" class="ml-4 mr-4" @click="jourMoinsUn()" :loading="loading">j - 1</v-btn>
          <v-btn color="success" class="ml-4 mr-4" @click="jourPlusUn()" :loading="loading">j + 1</v-btn>
        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-simple-table height="300px">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Date</th>
                        <th class="text-left">Message</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item, index in traces" :key="index">
                        <td>{{ item.date }}</td>
                        <td>{{ item.message }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Traces',
    props: {
        state: undefined,
        ip: String,
        port: Number,
        nomAppli: String
    },

    data () {
      return {
        dialog: false,
        loading:false,
        traces: Array,
        jour: undefined
      }
    },

    watch: {
      dialog(visible) {
        if (visible) {
          this.refresh();
          //console.log("Dialog was opened!")
        } else {
          //console.log("Dialog was closed!")
        }
      }
    },

      methods: {
        refresh() {
            this.loading = true;
          var url = "http://{ip}:{port}/Flybox/{nomAppli}/app/controllers/main/modules/redondance/Logs";
          if (this.jour != undefined) {
            url = url + "?date="+this.dateToString(this.jour);
          }
          url = url.replace("{ip}", this.ip);
          url = url.replace("{port}", this.port.toString());
          url = url.replace("{nomAppli}", this.nomAppli);
          this.traces = [];
          axios.get(url)
            .then(response => {
                //console.log(response.data);
                this.traces = response.data;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.traces = [];
                this.loading = false;
            });
      },
       
      dateToString(date) {
          var y = date.getFullYear().toString();
          var m = (date.getMonth() + 1).toString();
          if (m.length == 1) {
              m = "0" + m;
          }
          var d = (date.getDate()).toString();
          if (d.length == 1) {
              d = "0" + d;
          }
          return y + "-" + m + "-" + d;
      },

      aujourdhui() {
          this.jour = new Date();
          this.refresh();
      },

      jourPlusUn() {
          if (this.jour == undefined) {
              this.jour = new Date();
          }
          this.jour = new Date(this.jour.getTime() + 86400000)
          this.refresh();
      },

      jourMoinsUn() {
          if (this.jour == undefined) {
              this.jour = new Date();
          }
          this.jour = new Date(this.jour.getTime() - 86400000)
          this.refresh();
      }

    },

    mounted() {
        //this.refresh();
    }
  }
</script>