<template>
  <div class="text-center">
    <v-dialog v-model="ouvrirDialog" width="500">
        
      <v-card>
        <v-card-title class="headline red white--text lighten-2">
          La Flybox est bloquée !
        </v-card-title>

        <v-card-text class="red--text pt-4">
          La Flybox est bloquée définitivement (avant relance) en raison d'une erreur critique dans le script de redondance.
          Consulter les traces du dossier 'mod_redondance\logs' pour déterminer l'erreur.
          Voir avec le service de maintenance pour contrôler les états de la Flybox, couper puis relancer le(s) serveur(s) Flybox.
          Tant que le(s) serveur(s) ne seront pas relancé(s), la redondance ne sera pas opérationnelle !
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="ouvrirDialog = false">
            fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data () {
      return {
        ouvrirDialog: false,
      }
    },
}
</script>

<style>

</style>