<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      height="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark color="primary" v-bind="attrs" v-on="on">
          Comparer les fichiers
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" v-if="state != undefined">
          {{state.flybox}} {{nomAppli}} Comparaison des fichiers
          <v-btn color="success" class="ml-4 mr-4" @click="refresh()" :loading="loading">charger</v-btn>
          <v-switch label="Voir tous les fichiers" v-model="tousLesFichiers"></v-switch>
          <div class="ml-2" v-if="filesCount &gt; 0">{{filesCount}} fichiers</div>
          <div class="ml-5"> Filtre</div>
          <v-text-field class="mx-2 saisie red--text" label="fichiers à ignorer (séparés par des virgules)" v-model="saisieFiltre" style="width: 60px"></v-text-field>
          <v-btn color="success" text small @click="filtrer()">Filtrer</v-btn>

        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-simple-table height="300px">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Nom (source)</th>
                        <th class="text-left">Source</th>
                        <th class="text-left">Destination</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in filesFiltered" :key="item.SourceCompleteName">
                        <td>{{ item.SourceCompleteName }}</td>
                        <td>{{ item.SourceLastModified.substring(0,19).replace('T',' ') }}</td>
                        <td class="red--text">{{ item.DestExists ? item.DestLastModified.substring(0,19).replace('T',' ') : '???' }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ComparaisonFichiers',
    props: {
        state: undefined,
        ip: String,
        port: Number,
        nomAppli: String
    },

    data () {
      return {
        dialog: false,
        loading:false,
        files: Array,
        filesFiltered: Array,
        filesCount: 0,
        tousLesFichiers: false,
        saisieFiltre: ""
      }
    },

    watch: {
      dialog(visible) {
        if (visible) {
          this.refresh();
          //console.log("Dialog was opened!")
        } else {
          //console.log("Dialog was closed!")
        }
      }
    },

      methods: {
        refresh() {
            this.loading = true;
          var url = "http://{ip}:{port}/Flybox/{nomAppli}/app/controllers/main/modules/redondance/CompareFiles";
          if (this.tousLesFichiers) {
            url = url + "?onlyDifferent=0"
          }
          url = url.replace("{ip}", this.ip);
          url = url.replace("{port}", this.port.toString());
          url = url.replace("{nomAppli}", this.nomAppli);
          this.files = [];
          this.filesFiltered = [];
          this.filesCount = 0;
          axios.get(url)
            .then(response => {
                //console.log(response.data);
                this.files = response.data;
                this.filtrer();
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.filesCount = 0;
                this.loading = false;
            });
      },

      filtrer() {
        this.filesFiltered = this.files.filter(f => {
          var ok = true;
          var filters = this.saisieFiltre.split(",");
          filters.forEach(filter => {
            if (filter != "") {
              ok = ok && (f.SourceCompleteName.indexOf(filter) < 0);
              //console.log(f.SourceCompleteName, filter, f.SourceCompleteName.indexOf(filter), ok);
            }
          });
          return ok;
        });
        this.filesCount = this.filesFiltered.length;
      }

    },

    mounted() {
      //this.refresh();
    }
  }
</script>

<style scoped>
/deep/ .v-text-field{
      width: 200px;
}
.saisie {
  width: 400px;
}
</style>