<template>
<v-container grid-list-md>
    <v-layout row wrap>
        
        <v-flex xs6 class="blue--text">
             {{nom}}
        </v-flex>        

        <v-flex xs6 style="text-align: left">
             <div class="pa-1">{{valeur}}</div>
        </v-flex>        
    
    </v-layout>
    
</v-container>
</template>

<script>

export default {
  name: 'Propriere',
  props: {
    nom: String,
    valeur: undefined,
  },

  data: () => ({
  }),

  methods: {

  },

  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
